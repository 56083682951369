import React from "react";
import Icon from "@ant-design/icons";

const CKCloseSVG = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L21 21" stroke="black" strokeWidth="2"/>
    <path d="M1 21L21 1" stroke="black" strokeWidth="2"/>
  </svg>
);

const CKCloseIcon = (props: any) => (
  <Icon component={CKCloseSVG} {...props} />
);

export default CKCloseIcon;
